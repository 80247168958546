import * as React from "react";
import {Box, Flex, Heading, Image, Text} from "rebass";
import hdpIcon from "../assets/hdpIcon.svg";
import {useInstallationPrompt} from "./InstallPromtProvider";

export function AddToHomeScreenAndroid() {
    const {prompt} = useInstallationPrompt();


    if (!prompt) {
        return <div/>;
    }

    return (
        <Flex width={1} flexDirection={"column"} my={3} mx={3}>
            <Text m={2} mt={2} fontSize={14} textAlign={"left"}> Speichere dir die App, so dass du dann direkt
                fortfahren
                kannst.</Text>


            <button style={{borderWidth: 0}} onClick={() => prompt?.prompt()}>

                <Flex mx={2} alignItems={'center'} width={1} height={'90px'}>

                    <Image size={'70px'} px={2} src={hdpIcon}/>
                    <Heading>Zum Homescreen hinzufügen</Heading>
                </Flex>
                </button>

        </Flex>
    );
}
