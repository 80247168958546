import React, {useContext} from 'react';

interface IBeforeInstallPromptEvent extends Event {
    readonly platforms: string[];
    readonly userChoice: Promise<{
        outcome: "accepted" | "dismissed";
        platform: string;
    }>;

    prompt(): Promise<void>;
}


type State = {
    prompt: IBeforeInstallPromptEvent | null;
};

const StateContext = React.createContext<State>({
    prompt: null,
});

export const InstallPromptProvider = (props: { children: React.ReactNode }) => {

    const [deferredPrompt, setDeferredPrompt] = React.useState<any>(null);

    React.useEffect(() => {

        const listener = (e: any) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', listener);
        return () => window.removeEventListener('beforeinstallprompt', listener);
    }, []);

    React.useEffect(() => {
        const listener = () => {
            setDeferredPrompt(null);
        };
        window.addEventListener('appinstalled', listener);
        return () => window.removeEventListener('appinstalled', listener);
    }, []);

    return (

        <StateContext.Provider value={{prompt: deferredPrompt}}>
            {props.children}
        </StateContext.Provider>
    );
};

export const useInstallationPrompt = () => useContext(StateContext);
