import React, {useState} from "react";
import {Button, Flex, Heading, Text} from "rebass";
import {Question} from "../types/question";
import {Correct} from "../types/correct";
import {boxFrame} from "../styles/boxFrame";
import {buttonDistance} from "../helper/buttonDistance";
import {ImprintComponent} from "./ImprintComponent";
import {Route} from "react-router-dom";

type QuestionProps = {
    step: number,
    questionsCount: number,
    question: Question,
    handleShowAnswer: (userAnswer: Correct) => void,
}


export function QuestionComponent({step, questionsCount, question, handleShowAnswer,}: QuestionProps) {
    const [answer, setAnswer] = useState<Correct | undefined>();
    const [nextButtonActive, setNextButtonActive] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);


    let rightButtonStyle = answer === Correct.CORRECT ? 'answerSelected' : 'answer';
    let wrongButtonStyle = answer === Correct.WRONG ? 'answerSelected' : 'answer';
    let nextButtonStyle = nextButtonActive ? 'primary' : 'disabled';

    function validateAnswer() {
        answer != null ? handleShowAnswer(answer) : setErrorMessage(true)
    }

    function handleUserInput(answer: Correct) {
        setAnswer(answer);
        setNextButtonActive(true);
        setErrorMessage(false)
    }

    return (
        <Flex flex={1} sx={boxFrame} flexDirection={"column"}>
            <Text p={2} fontSize={14} sx={{textAlign: "right"}}>{step + 1} von {questionsCount}</Text>
            <Flex flex={1} flexDirection={"column"} alignItems={'center'}>
                <Heading px={3} mt={4}>{question.question}</Heading>
            </Flex>
            <Flex flex={1} mt={4} alignItems={'center'} flexDirection={"column"} justifyContent={'center'}>
                <Button variant={rightButtonStyle}
                        mb={2}
                        name='right'
                        onClick={() => handleUserInput(Correct.CORRECT)}>
                    Richtig
                </Button>
                <Button
                    variant={wrongButtonStyle}
                    mb={2}
                    name='wrong'
                    onClick={() => handleUserInput(Correct.WRONG)}>Falsch</Button>
                <Text color={'wrong'}>{errorMessage ? 'Bitte wähle eine Antwort aus!' : null}</Text>
            </Flex>
            <Flex flex={1} alignItems={'flex-end'} mb={buttonDistance} justifyContent={'center'}>

                <Button
                    variant={nextButtonStyle}
                    type="submit"
                    value="Submit"
                    onClick={validateAnswer}>Weiter</Button>
            </Flex>
        </Flex>
    )


}
