import {UserAnswer} from "../types/userAnswer";

export default function (userAnswer: UserAnswer[]) {
    let counter = 0;
    userAnswer.map(userAnswer => {
        if (userAnswer.isUserAnswerCorrect === userAnswer.isStatementCorrect) {
            counter++
        }
    });

    return (
        counter
    )

}
