import React from 'react';
import './App.css';
import {ThemeProvider} from 'emotion-theming'
import {StartPage} from "./pages/StartPage";
import {theme} from "./styles/theme";
import {QuizPage} from "./pages/QuizPage";
import {
    Switch,
    Route,
} from "react-router-dom";
import {InstallPromptProvider} from "./components/InstallPromtProvider";
import {ImprintComponent} from "./components/ImprintComponent";
import {ImprintPage} from "./pages/ImprintPage";
import 'typeface-noto-sans-tc';

function App() {

    return (
        <InstallPromptProvider>
            <ThemeProvider theme={theme}>
                <Switch>
                    <Route path="/quiz">
                        <QuizPage/>
                        <ImprintComponent/>
                    </Route>
                    <Route path="/impressum">
                        <ImprintPage/>
                    </Route>
                    <Route path="/">
                        <StartPage/>
                        <ImprintComponent/>
                    </Route>
                </Switch>
            </ThemeProvider>
        </InstallPromptProvider>
    );
}

export default App;
