import React from "react";
import {Box, Button, Flex, Heading, Text} from "rebass";
import {useHistory} from "react-router-dom";
import {boxFrame} from "../styles/boxFrame";

export function ImprintPage() {

    const history = useHistory();

    const routeChange = () => {

        history.goBack();
    };


    return (
        <>

            <Flex flex={1} flexDirection={"column"} sx={boxFrame}>
                <Flex flex={1}>
                    <Box px={3}>

                        <Heading mt={3}>Impressum / Datenschutz</Heading>
                        <Text variant={'imprint'} mb={2} mt={2}>
                            Stiftung Haus der Geschichte Nordrhein-Westfalen<br/><br/>
                            Jägerhofstraße 29<br/>
                            40479 Düsseldorf<br/>
                            Telefon: 0211 513 613-0<br/>
                            Internet: <a href={'www.hdg.nrw.de'} target={'_blank'}
                                         rel="noopener noreferrer"> www.hdg.nrw.de </a><br/>
                            <Text variant={'imprint'} mb={2} mt={2}>
                                Konzeption/Redaktion: <br/>
                                Dr. Florian Melchert (verantwortlich), Birgit Dransfeld<br/><br/>
                                Technische Realisation:<br/>
                                <a href={'https://lumind-solutions.com'}>lumind solutions GmbH</a>, Berlin<br/>
                            </Text>
                        </Text>
                        <Text variant={'imprint'} mb={2} mt={4}>
                            Es werden nur Informationen erhoben, die bei der Nutzung des Internets technisch zwingend
                            notwendig sind, um die Inhalte der Website korrekt auszuliefern.
                            Die Bestimmungen der DSGVO und die Vorgaben der Landesbeauftragten für den Datenschutz <a
                            href={'https://www.ldi.nrw.de/'} target={'_blank'}
                            rel="noopener noreferrer">ldi.nrw.de</a> werden beachtet.<br/>

                        </Text>


                    </Box>

                </Flex>
                <Flex flex={1} alignItems={'flex-end'} mb={4} justifyContent={'center'}>
                    <Button variant='primary' onClick={routeChange}>
                        zurück
                    </Button>
                </Flex>

            </Flex>

        </>
    )

}
