import {QUESTIONS_LEVEL_ONE} from "../questions/questions";

export default function (level: string) {

    if (level === "before") {
        return QUESTIONS_LEVEL_ONE.length / 2;
    } else {
        return QUESTIONS_LEVEL_ONE.length;

    }


}
