import React from "react";
import {Box, Button, Flex, Heading, Image, Text} from "rebass";
import {useHistory} from "react-router-dom";
import VillaImage from "../assets/Villa_Horion,_Johannes-Rau-Platz_(Düsseldorf)_02.jpg"
import ccIcon from "../assets/by-sa.png"
import {boxFrame} from "../styles/boxFrame";
import {buttonDistance} from "../helper/buttonDistance";

export function StartPage() {

    const history = useHistory();

    const routeChange = () => {
        let path = `quiz`;
        history.push(path);
    };

    return (
        <Flex flex={1} sx={boxFrame} flexDirection={"column"}>
            <Flex flex={1} flexDirection={"column"}>
                <Box px={3}>
                    <Image
                        mt={2}
                        src={VillaImage}
                        sx={{
                            borderRadius: 8,
                        }}
                    />
                    <Text textAlign={'right'} fontSize={10} color={'grey'}>Villa Horion. Quelle: Wikimedia/Jula2812. <a
                        rel="license" target={'blank'} href="http://creativecommons.org/licenses/by-sa/3.0/"> <Image
                        maxWidth={25} src={ccIcon}/>
                    </a>
                    </Text>
                    <Heading mt={3}>Parlamentshistorisches Wissensquiz</Heading>
                    <Text variant={'paragraph'} mb={2} mt={2}>
                        Was weißt du bisher über Nordrhein-Westfalen und seine
                        Geschichte?
                    </Text>
                </Box>
            </Flex>
            <Flex flex={1} alignItems={'flex-end'} mb={buttonDistance} justifyContent={'center'}>
                <Button variant='primary' onClick={routeChange}>
                    Weiter
                </Button>
            </Flex>
        </Flex>
    )
}
