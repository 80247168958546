export const theme = {

    fontSizes: [
        12, 14, 16, 24, 32, 48, 64
    ],
    colors: {
        primary: '#2C374B',
        secondary: '#3DB2CE',
        white: '#fff',
        gray: '#626262',
        lightGrey: '#EDEDED',
        greyBlue: '#D9E2E5',
        steelBlue: '#3DB2CE',
        wrong: '#DB2A1B',
        right: '#20A659',
        wrongLight: '#F7CDCA',
        rightLight: '#CBEAD8',
        black: '#000000',
        gold: '#FDB131'

    },
    fonts: {
        body: 'Noto Sans TC',
        heading: 'Noto Sans TC',
        monospace: 'Menlo, monospace',
        buttons: 'Noto Sans TC'
    },
    buttons: {
        primary: {
            color: 'white',
            bg: 'primary',
            textTransform: 'uppercase',
            letterSpacing: '1.26pt',
            fontSize: '1.1em',
            borderRadius: '7pt',
            height: '3em',
            width: '80%',
            fontWeight: 'bold',
            outline: 'none'

        },
        secondary: {
            bg: 'secondary',
            outline: 'none'
        },
        answer: {
            color: 'primary',
            bg: 'transparent',
            textTransform: 'uppercase',
            letterSpacing: '1.26pt',
            fontSize: '1.1em',
            borderRadius: '7pt',
            height: '3em',
            width: '80%',
            fontWeight: 'bold',
            boxShadow: 'inset 0 0 0  2px lightGrey',
            outline: 'none'

        },
        answerSelected: {
            color: 'white',
            bg: 'secondary',
            textTransform: 'uppercase',
            letterSpacing: '1.26pt',
            fontSize: '1.1em',
            borderRadius: '7pt',
            height: '3em',
            width: '80%',
            fontWeight: 'bold',
            // boxShadow: 'inset 0 0 0  2px lightGrey',
            outline: 'none'

        },
        disabled: {
            color: 'grey',
            bg: 'lightGrey',
            textTransform: 'uppercase',
            letterSpacing: '1.26pt',
            fontSize: '1.1em',
            borderRadius: '7pt',
            height: '3em',
            width: '80%',
            fontWeight: 'bold',
            boxShadow: 'inset 0 0 0  2px lightGrey',
            outline: 'none'

        },
        outline: {
            color: 'primary',
            bg: 'transparent',
            boxShadow: 'inset 0 0 0 2px',
            outline: 'none'
        },
    },
    text: {
        headline: {
            color: 'primary'
        },
        paragraph: {
            color: 'grey',
            textAlign: 'left',
            fontSize: '1.3em',
            lineHeight: '20pt'
        },
        heading: {
            fontSize: '1.2em',
            color: 'primary',
            textAlign: 'left',
            lineHeight: '1.4em'

        },
        result: {
            fontSize: '1.4em',
            color: 'gold',
            textAlign: 'center',
            lineHeight: '1.4em',
            fontWeight: 'bold'
        },
        imprint: {
            color: 'black',
            fontSize: '12px'
        }

    },
    forms: {
        input: {
            color: 'primary',

            borderColor: 'primary'

        },
        select: {
            borderRadius: 9999,

        },
        wrongInput: {
            color: 'wrong',

            borderColor: 'wrong',
        },
        rightInput: {
            color: 'right',

            borderColor: 'right',
        }
    },
    variants: {
        right: {
            bg: 'rightLight'
        },
        wrong: {
            bg: 'wrongLight'
        }
    },

};
