import React, {useState} from "react";
import {Box, Button, Flex, Heading, Text} from "rebass";
import {CodePage} from "./CodePage";
import {AddToHomeScreenAndroid} from "../components/AddToHomeScreenAndroid";
import {questionsCounter, calculateRightAnswers} from "../helper";
// @ts-ignore
import PWAPrompt from 'react-ios-pwa-prompt'
import {buttonDistance} from "../helper/buttonDistance";
import {UserAnswer} from "../types/userAnswer";
import {boxFrame} from "../styles/boxFrame";

type QuestionProps = {
    userAnswers: UserAnswer[],
    handleCode: (userCode: boolean) => void,

}

export function ResultsInbetweenPage({userAnswers, handleCode}: QuestionProps) {

    const [showCode, setShowCode] = useState(false);

    return (

        !showCode ?
            <Flex flex={1} sx={boxFrame} flexDirection={"column"}>
                <Flex flex={1} alignItems={'flex-start'} justifyContent={'center'}>
                    <Box px={3}>
                        <Heading fontSize={28} mt={4}>Dein Zwischenergebnis:
                            <br/> {calculateRightAnswers(userAnswers).toString()} von {questionsCounter("before")} richtig
                        </Heading>
                        <Text className={'slide-top'} mt={2} variant={'paragraph'}>
                            Die Fortsetzung folgt am Ende des Ausstellungsbesuchs im Haus der Parlamentsgeschichte.
                        </Text>
                    </Box>
                </Flex>
                <Flex justifyContent={'center'} flex={1}>
                    <AddToHomeScreenAndroid/>
                </Flex>
                <Flex flex={1} alignItems={'flex-end'} mb={buttonDistance} justifyContent={'center'}>
                    <Button onClick={() => setShowCode(true)}> weiter</Button>
                    <PWAPrompt
                        permanentlyHideOnDismiss={false}
                        timesToShow={1}
                        copyShareButtonLabel={'Drücke das Teilen-Icon'}
                        copyClosePrompt={'Abbrechen'}
                        copyAddHomeButtonLabel={'Zum Bildschirm'}
                        copyBody={'Damit du nach Deinem Besuch im Haus der Parlamentsgeschichte direkt weiter machen kannst!'}
                        copyTitle={'Zum Startbildschirm hinzufügen'}
                    />

                </Flex>
            </Flex>

            :
            <CodePage handleCode={handleCode}/>
    )
}
