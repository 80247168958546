import React, {FC, useEffect} from 'react';
import {Snackbar, Button} from '@material-ui/core';
import * as serviceWorker from '../serviceWorker';

const ServiceWorkerWrapper: FC = () => {
    const [showReload, setShowReload] = React.useState(false);

    const onSWUpdate = (registration: ServiceWorkerRegistration) => {
        setShowReload(true);
    };

    useEffect(() => {
        serviceWorker.register({onUpdate: onSWUpdate});
        console.log("ServiceWordker Register")
    }, []);

    const reloadPage = () => {
        setShowReload(false);
        window.location.reload(true);
    };


    return (
        <Snackbar
            open={showReload}
            message="Es ist ein Update verfügbar"
            onClick={reloadPage}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            action={
                <Button
                    color="inherit"
                    size="small"
                    onClick={reloadPage}
                >
                    Neu laden
                </Button>
            }
        />
    );
};

export default ServiceWorkerWrapper;
