import {Correct} from "../types/correct";
import {Question} from "../types/question";

export const QUESTIONS_LEVEL_ONE: Question[] = [
    {
        question: 'Das nordrhein-westfälische Wappen ist dreigeteilt. Es zeigt für das Rheinland links den Rhein, für ' +
            'Westfalen rechts das westfälische Ross und für den Landesteil Lippe unten die lippische Rose.',
        answer: 'Das nordrhein-westfälische Wappen ist dreigeteilt. Es zeigt für das Rheinland links den Rhein,' +
            ' für Westfalen rechts das westfälische Ross und für den Landesteil Lippe unten die lippische Rose.',
        correct: Correct.CORRECT,
    },
    {
        question: 'Das Land Nordrhein-Westfalen wurde bei der Gründung des Deutschen Reichs 1871 gebildet.',
        answer: 'Das Land Nordrhein-Westfalen wurde 1946 durch die britische Besatzungsmacht gebildet.',
        correct: Correct.WRONG,
    },
    {
        question: 'Nach dem Zweiten Weltkrieg konnten Politik und Wirtschaft des Landes sofort ohne Einschränkungen weiterarbeiten.',
        answer: 'Nach dem Zweiten Weltkrieg mussten sich Politik und Wirtschaft zunächst mehrere Jahre dem Willen der' +
            ' britischen Besatzungsmacht unterordnen.',
        correct: Correct.WRONG,
    },
    {
        question: 'Das Mindest-Wahlalter für die Landtagswahl in Nordrhein-Westfalen liegt derzeit bei 18 Jahren.',
        answer: 'Das Mindestalter für die Landtagswahl in Nordrhein-Westfalen liegt bei 18 Jahren. ' +
            'Bei den Kommunalwahlen darf ab 16 Jahren gewählt werden.',
        correct: Correct.CORRECT,
    },
    {
        question: 'Frauen und Männer müssen im Landtag Nordrhein-Westfalen in gleicher Anzahl vertreten sein.',
        answer: 'Es gibt keine gesetzliche Regelung, die festlegt, dass Frauen und Männer in gleicher Anzahl im ' +
            'Landtag Nordrhein-Westfalen vertreten sein müssen.',
        correct: Correct.WRONG,
    },
    {
        question: 'Im Landtag Nordrhein-Westfalen waren in den bisherigen Wahlperioden jeweils zwischen zwei und fünf Parteien vertreten.',
        answer: 'In den ersten Wahlperioden und auch heute wieder sind fünf Parteien im Landtag vertreten, ' +
            '1980 zogen nur zwei Parteien in den Landtag ein.',
        correct: Correct.CORRECT,
    },
    {
        question: 'In Nordrhein-Westfalen war die Dauer der Wahlperiode des Landtags von Anfang an auf fünf Jahre festgelegt.',
        answer: 'Die Wahlperioden wurden erst 1969 von vier auf fünf Jahre verlängert. Die erste Wahlperiode dauerte sogar nur drei Jahre.',
        correct: Correct.WRONG,
    },
    {
        question: 'Im Landtag Nordrhein-Westfalen hat es bislang zweimal ein konstruktives Misstrauensvotum gegen einen ' +
            'amtierenden Ministerpräsidenten gegeben.',
        answer: 'Im Landtag Nordrhein-Westfalen kam es zweimal, 1956 und 1966, zu einem konstruktiven Misstrauensvotum.',
        correct: Correct.CORRECT,
    },
    {
        question: 'Das Plenum, die Vollversammlung der Abgeordneten, tagte auch in Phasen großer Raumnot öffentlich, ' +
            'das heißt Zuschauerinnen und Zuschauer waren zugelassen.',
        answer: 'Das Plenum tagte von Anfang an grundsätzlich öffentlich. Auf Antrag konnte die Öffentlichkeit allerdings ' +
            'für einzelne Tagesordnungspunkte ausgeschlossen werden. Das gilt noch heute.',
        correct: Correct.CORRECT,
    },
    {
        question: 'Karl Arnold war der erste Ministerpräsident Nordrhein-Westfalens.',
        answer: 'Der erste Ministerpräsident Nordrhein-Westfalens war Rudolf Amelunxen (parteilos).' +
            ' Amelunxen wurde von der britischen Besatzungsmacht ernannt. Karl Arnold (CDU) war der erste ' +
            'gewählte Ministerpräsident Nordrhein-Westfalens. Übrigens: Der Ministerpräsident mit der längsten Amtszeit war Johannes Rau (SPD).',
        correct: Correct.WRONG,
    },

];
