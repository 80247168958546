import React from "react";
import {Box, Button, Flex, Heading, Image, Text} from "rebass";
import rightIcon from '../assets/blackRightIcon.svg'
import wrongIcon from '../assets/blackWrongIcon.svg'
import {useHistory} from "react-router-dom";
import {UserAnswer} from "../types/userAnswer";
import {questionsCounter, calculateRightAnswers} from "../helper";
import {ResultsComponent} from "../components/ResultsComponent";

type QuestionProps = {
    userAnswers: UserAnswer[];
    clearCash: () => void

}

export const ResultsPage = (props: QuestionProps) => {

    const history = useHistory();

    const routeChange = () => {
        //ToDo: Bubble up to QuizPage
        // window.localStorage.clear();
        props.clearCash();
        let path = `/`;
        history.push(path);
    };

    return (

        <Flex flex={1}
              minHeight={'100%'}
              sx={{maxWidth: 512, mx: 'auto',}}
              flexDirection={"column"}
        >
            <Flex flex={1} flexDirection={"column"}>
                <Heading mt={4} textAlign={'center'}>Geschafft!</Heading>
                <ResultsComponent userAnswers={props.userAnswers}/>
                <Box px={3}>
                    <Heading fontSize={25} textAlign={'left'} variant={'heading'} mb={2} mt={6}>Du hast
                        dich {calculateRightAnswers(props.userAnswers).toString()} von {questionsCounter("after").toString()} Mal
                        richtig entschieden.</Heading>
                </Box>
                {
                    props.userAnswers.map((item: UserAnswer, index) =>

                        (


                            <Flex alignItems={'center'} flexDirection={"column"} mt={2}>
                                <Box p={3} mt={2}
                                     variant={item.isStatementCorrect ? 'right' : 'wrong'}>
                                    <Text variant={'paragraph'} fontSize={12} color={'black'}
                                          textAlign={"left"}> {index + 1}. </Text>
                                    <Text variant={'paragraph'} color={'black'}> {item.statement}</Text>
                                    <Flex alignItems={'center'} flexDirection={'column'}>
                                        <Image mt={4}
                                               src={item.isUserAnswerCorrect === item.isStatementCorrect ? rightIcon : wrongIcon}
                                        />
                                        <Text mt={2}>Dein Ergebnis</Text>

                                    </Flex>
                                </Box>

                            </Flex>


                            /*<Box p={2} mt={2}
                                 variant={item.isUserAnswerCorrect === item.isStatementCorrect ? 'right' : 'wrong'}>
                                <Text variant={'paragraph'}>Aussage {index + 1} </Text>
                                <Text variant={'paragraph'}> {item.statement}</Text>
                                <Flex justifyContent={'space-between'} mt={3}>
                                      <Flex alignItems={'center'} flexDirection={'column'}>
                                        <Image
                                            src={item.isUserAnswerCorrect ? rightIcon : wrongIcon}/>
                                        <Text mt={2}>Deine Antwort</Text>
                                    </Flex>
                                   <Flex alignItems={'center'} flexDirection={'column'}>
                                        <Image
                                            src={item.isStatementCorrect ? rightIcon : wrongIcon}/>
                                        <Text mt={2}>Richtige Antwort</Text>
                                    </Flex>
                                </Flex>
                            </Box>*/
                        )
                    )}


                {/*        <Image
                    mt={4}
                    px={3}
                    src={'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7c/Sitz_des_Landtagspräsidenten_NRW.jpg/1024px-Sitz_des_Landtagspräsidenten_NRW.jpg'}
                    sx={{
                        width: ['100%', '50%'],
                        borderRadius: 8,
                    }}
                />*/}

                <Heading mx={3} my={4}>Danke für Deine Aufmerksamkeit.<br/>
                    Auf Wiedersehen!</Heading>
            </Flex>
            <Flex flex={1} alignItems={'flex-end'} mb={4} justifyContent={'center'}>
                <Button variant='primary' onClick={routeChange} mt={4}>Quiz neu starten</Button>
            </Flex>

        </Flex>

    )
};
