import {Button, Flex, Image, Text} from "rebass";
import {boxFrame} from "../styles/boxFrame";
import React from "react";
import rightSVG from "../assets/right.svg";
import wrongSVG from "../assets/wrongIcon.svg";
import {Question} from "../types/question";
import {Correct} from "../types/correct";
import {buttonDistance} from "../helper/buttonDistance";

type StatementProps = {
    question: Question,
    nextQuestion: () => void,
    userAnswer: Correct | undefined
}

export function RightStatementPage({question, nextQuestion, userAnswer}: StatementProps) {
    let modal = userAnswer === question.correct ? 'overlayRight' : 'overlayWrong';
    let chooseIcon = userAnswer === question.correct ? rightSVG : wrongSVG;
    return (
        <>
            <Flex
                className='iconAnimation'
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Image
                    src={chooseIcon}/>
            </Flex>
            <div className={modal}/>
            <Flex

                flexDirection={"column"}
                sx={boxFrame}
            >
                <Flex flex={1} flexDirection={"column"} my={4} justifyContent={'center'}>

                    <Text mx={3} className='AnswerAnimation' variant={'paragraph'}
                    >{question.answer}
                    </Text>
                </Flex>
                <Flex flex={1}
                      alignItems={'flex-end'}
                      mb={buttonDistance} justifyContent={'center'}
                >
                    <Button variant='primary' onClick={() => nextQuestion()}>
                        Weiter
                    </Button>
                </Flex>
            </Flex>
        </>
    )
}
