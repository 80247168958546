import React from "react";
import {Flex, Image, Text} from "rebass";
import {UserAnswer} from "../types/userAnswer";
import {calculateRightAnswers} from "../helper";
import resultIcon from "../assets/award.svg"

type QuestionProps = {
    userAnswers: UserAnswer[];
}

export function ResultsComponent(props: QuestionProps) {

    const rightAnswers = calculateRightAnswers(props.userAnswers);

    function sentence() {
        switch (rightAnswers) {

            case 10:
                return "Perfekt! Besser geht's nicht!";

            case 9:
            case 8:
                return "Das hast du richtig gut gemacht!";

            case 7:
            case 6:
                return "Das hast du schon ganz ordentlich gemacht!";

            case 5:
            case 4:

                return "Dein Ergebnis ist noch ausbaufähig!";

            case 3:
            case 2:

                return "Da müsste doch noch mehr zu machen sein!";

            case 0:
            case 1:

                return "Hm?! Was war los mit dir?";

            default:
                console.log("No Results")

        }
    }

    return (

        <Flex width={1} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
            {rightAnswers >= 6 ?
                < Image
                    mt={4}
                    className={'bounce-in-bck'}
                    src={resultIcon}/>
                : null}
            <Text mt={2} variant={'result'}>{sentence()}</Text>
        </Flex>

    )

}
