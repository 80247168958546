import React, {useState} from "react";
import {QuestionComponent} from "../components/QuestionComponent";
import {Question} from "../types/question";
import {Correct} from "../types/correct";
import {RightStatementPage} from "./RightStatementPage";

type QuestionProps = {
    step: number,
    questionsCount: number,
    question: Question,
    next: (userAnswer: Correct) => void,
}

export function QuestionPage({step, questionsCount, question, next}: QuestionProps) {
    const [showAnswer, setShowAnswer] = useState(false);
    const [userAnswer, setUserAnswer] = useState<Correct | undefined>();

    function nextQuestion() {
        setShowAnswer(false);
        if (userAnswer !== undefined) {
            next(userAnswer)
        }
    }

    function handleShowAnswer(userAnswer: any) {
        setShowAnswer(true);
        window.scrollTo(0, 0);
        setUserAnswer(userAnswer)
    }
    return (
        !showAnswer ?
            <QuestionComponent
                step={step}
                questionsCount={questionsCount}
                question={question}
                handleShowAnswer={handleShowAnswer}
            />
            :
            <RightStatementPage
                question={question}
                nextQuestion={nextQuestion}
                userAnswer={userAnswer}
            />
    )

}
