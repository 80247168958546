import React from "react";
import {Box, Text} from "rebass";
import {useHistory} from "react-router-dom";

export function ImprintComponent() {
    const history = useHistory();

    const routeChange = () => {
        let path = `impressum`;
        history.push(path);
    };
    return (
        <Box mt={4} onClick={routeChange}>
            <Text p={2} fontSize={12} textAlign={'right'} sx={{textDecoration: "underline"}}>
                Impressum / Datenschutz
            </Text>
        </Box>
    )

}
