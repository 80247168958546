import React, {useEffect, useState} from "react";
import {Box, Button, Flex, Heading, Text} from "rebass";
import {Input} from '@rebass/forms'
import {passwords} from '../helper/passwords'
import {buttonDistance} from "../helper/buttonDistance";
import {boxFrame} from "../styles/boxFrame";

type Props = { handleCode: (userCode: boolean) => void };


export function CodePage({handleCode}: Props) {

    const [code, setCode] = useState('');
    const [inputColor, setInputColor] = useState('input');
    const [errorMessage, setErrorMessage] = useState(false);

    const nextButtonStyle = code ? 'primary' : 'disabled';

    function onNext() {
        const isPasswordCorrect = passwords.includes(code.trim().toUpperCase());

        if (isPasswordCorrect) {
            handleCode(true);
        }
        setInputColor(isPasswordCorrect ? 'rightInput' : 'wrongInput');
        setErrorMessage(!isPasswordCorrect);
        window.scrollTo(0, 0);
    }

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        let trimmedInput = e.target.value.trim();
        const isPasswordCorrect = passwords.includes(trimmedInput.toUpperCase());

        setInputColor(isPasswordCorrect ? 'rightInput' : 'primary');
        setErrorMessage(false);
        setCode(trimmedInput);
    }


    return (

        <Flex flex={1} sx={boxFrame} flexDirection={"column"}>
            <Flex flex={1}>
                <Box px={3}>
                    <Heading mt={4}>
                        Fortsetzung des Quiz im Haus der Parlamentsgeschichte
                    </Heading>
                </Box>
            </Flex>
            <Flex flex={1} mt={4} alignItems={'center'} flexDirection={"column"} justifyContent={'center'}>
                <Box px={3}>

                    <Text mt={2} variant={'paragraph'}>Gib das Passwort ein! Es wird dir am Ende der Führung mitgeteilt.
                    </Text>
                    <Input
                        variant={inputColor}
                        onKeyPress={(ev) => {
                            if (ev.key === 'Enter') {
                                onNext();
                                ev.preventDefault();
                            }
                        }}
                        mt={4}
                        mb={1}
                        id='pw'
                        name='pw'
                        type='text'
                        value={code}
                        placeholder='Passwort eingeben'
                        onChange={handleChange}
                        sx={{
                            borderRadius: 7,
                            borderWidth: 2,
                            height: 50,
                            fontSize: 20,
                            fontWeight: "bold"
                        }}
                    />
                    <Text color={'wrong'}>{errorMessage ? 'Falsches Passwort!' : null}</Text>

                </Box>
            </Flex>
            <Flex flex={1} alignItems={'flex-end'} mb={buttonDistance} justifyContent={'center'}>
                <Button variant={nextButtonStyle} onClick={onNext}>weiter</Button>
            </Flex>
        </Flex>
    )
}
