import React, {useEffect, useState} from "react";
import '../styles/overlayAnimation.css'
import {QUESTIONS_LEVEL_ONE} from '../questions/questions'
import {ResultsPage} from "./ResultsPage";
import {QuestionPage} from "./QuestionPage";
import {ResultsInbetweenPage} from "./ResultsInbetweenPage";
import {Correct} from "../types/correct";
import {UserAnswer} from "../types/userAnswer";

export function QuizPage() {

    //local storage
    useEffect(() => {
        const initialUserAnswers = window.localStorage.getItem('userAnswersStorage');
        if (initialUserAnswers) {
            setUserAnswers(JSON.parse(initialUserAnswers));
        }

        const initialStep = window.localStorage.getItem('stepStorage');
        if (initialStep !== null) {
            setStep(JSON.parse(initialStep))
        }

    }, []);

    const questions = QUESTIONS_LEVEL_ONE;
    const [step, setStep] = useState(0);
    const [userAnswers, setUserAnswers] = useState<UserAnswer[]>([]);
    const [code, setCode] = useState(false);

    const questionsCount = QUESTIONS_LEVEL_ONE.length;

    function handleCode(userCode: boolean) {
        setCode(userCode)
    }

    function next(userAnswer: Correct) {
        setUserAnswers((preState) => [
            ...preState,
            {
                statement: questions[step].question,
                answer: questions[step].answer,
                isStatementCorrect: questions[step].correct,
                isUserAnswerCorrect: userAnswer,
            }
        ]);

        setStep(prevStep => prevStep + 1);
    }

    function clearCache() {
        window.localStorage.clear();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        window.localStorage.setItem("stepStorage", String(step))
    }, [step]);


    useEffect(() => {
        window.localStorage.setItem("userAnswersStorage", JSON.stringify(userAnswers))
    }, [userAnswers]);

    if (step >= questionsCount) {
        return <ResultsPage
            userAnswers={userAnswers}
            clearCash={clearCache}
        />
    }

    if (step === 5 && !code) {
        return <ResultsInbetweenPage
            userAnswers={userAnswers}
            handleCode={handleCode}
        />
    }

    return (
        <QuestionPage
            step={step}
            questionsCount={questionsCount}
            question={questions[step]}
            next={next}
        />

    )
}
